/**
 * Initializes the Data Table library and table on certain pages.
 */
import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';

let tableName: string;
/**
 * Initiates tables and rearranges related UI.
 * Used on the feedback, pdf submission, and bibliography pages.
 */
export function initDataTable (): void {
    tableName = $( '#pg-container' ).data( "dt" );
    if ( tableName ) { initPageTable(); }
}
function initPageTable (): void {
    initOiDataTable();
    if ( ifControlsNeedRelocated() ) { relocateTableControls(); }
}
function ifControlsNeedRelocated (): boolean {
    if ( window.outerWidth < 1280 && tableName !== 'biblio_tbl' ) { return true; } //Popup is displayed directing users to view page on computer.
    return tableName !== 'online_users_tbl';
}
/* ========================== INIT DATA-TABLE =============================== */
function initOiDataTable (): void {
    const $tableElem = $( '#' + tableName );
    //@ts-expect-error "DataTable types need to be added"
    $tableElem.DataTable( getTableConfig( getTableParams( tableName ) ) );
}
function getTableConfig<T extends ReturnType<typeof getTableParams>> ( params: T ): { columnDefs: { orderable: boolean; targets: number[]; }[]; lengthMenu: readonly [25, 50, 100] | readonly [25, 50, 100, 500]; language: { search: string; }; dom: string; buttons: { extend: string; className: string; exportOptions: { columns: number[]; }; }[]; } {
    return {
        columnDefs: [ { orderable: false, targets: [ 0 ] } ],
        lengthMenu: params.pagination,
        language: { search: 'Filter: ' },
        dom: '<"#tbl-ctrl-div"lBf>tip', //table elem display order
        buttons: [ {
            extend: 'copy',
            className: 'dt-bttn',
            exportOptions: { columns: params.exportColumns }
        }, {
            extend: 'csv',
            className: 'dt-bttn',
            exportOptions: { columns: params.exportColumns }
        } ]
    };
}
function getTableParams () {
    const tables = {
        feedback_tbl: [ 5, 100 ],
        online_users_tbl: [ 0, 100 ],
        biblio_tbl: [ 0, 500 ],
        pub_pdf_tbl: [ 2, 100 ],
    } as const;
    const table = tables[ tableName as keyof typeof tables ];
    return {
        exportColumns: getExportColumns( table[ 0 ] ),
        pagination: getPagination( table[ 1 ] ),
    };
}
function getExportColumns ( lastShownColIdx = 0 ): number[] {
    const ary:number[] = [];
    for ( let i = 0; i <= lastShownColIdx; i++ ) {
        ary.push( i );
    }
    return ary;
}
function getPagination<T extends keyof typeof pgLengths> ( dataLength: T ): typeof pgLengths[T] {
    const pgLengths = {
        100: [ 25, 50, 100 ],
        500: [ 25, 50, 100, 500 ],
        7000: [ 25, 50, 100, 500, 1000, 7000 ]
    } as const;
    return pgLengths[ dataLength ];
}
/* ======================== RELOCATE CONTROLS =============================== */
function relocateTableControls (): void {
    const $container = getNewContainer();
    $container.append( detachElem( '_filter' ) );
    if ( shouldMoveLength() ) {
        $container.prepend( detachElem( '_length' ) );
    }
    responsivelyAttachControls( $container );
}
function detachElem ( type: string ): JQuery<HTMLElement> {
    return $( `#${ tableName }${ type }` )?.detach();
}
function getNewContainer (): JQuery<HTMLElement> {
    const $container = $( '#tbl-ctrl-div .dt-buttons' ).detach();
    $container.attr( { class: 'flex-row', id: 'btn-div' } );
    return $container;
}
function shouldMoveLength (): boolean {
    return tableName !== 'biblio_tbl' || window.outerWidth > 1220;
}
function responsivelyAttachControls ( $container: JQuery<HTMLElement> ): void {
    if ( window.outerWidth < 555 ) {
        $( '#content-detail' ).prepend( $container );
    } else {
        $( '#headline-right' ).append( $container );
        ifBiblioPageMoveTableInfoAndPageSelect();
    }
}
function ifBiblioPageMoveTableInfoAndPageSelect (): void {
    if ( tableName !== 'biblio_tbl' ) return;
    $( `#${ tableName }` ).before( detachElem( '_info' ) );
    $( `#${ tableName }` ).before( detachElem( '_paginate' ) );
}

